import { render, staticRenderFns } from "./maintenance-costs.vue?vue&type=template&id=78437420&scoped=true&"
import script from "./maintenance-costs.vue?vue&type=script&lang=js&"
export * from "./maintenance-costs.vue?vue&type=script&lang=js&"
import style0 from "./maintenance-costs.vue?vue&type=style&index=0&id=78437420&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78437420",
  null
  
)

export default component.exports
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js"
import QSelect from 'quasar/src/components/select/QSelect.js'
import QImg from 'quasar/src/components/img/QImg.js'
qInstall(component, 'components', {QSelect,QImg})
